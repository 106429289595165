import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class Card13 extends Component {
    render() {
        const { title, type, id } = this.props;

        const link = type === 'product' ? `/products/${id}` : `/details/${id}`;
        return (
            <Link to={link} >
                <div className=" cursor-pointer p-[4px] border-[1px] border-[#fff] bg-[#dbdbdb]">
                    <p className='font-[400] text-[15px]'>{title}</p>
                </div>
            </Link>



        )
    }
}

export default Card13