import axios from "axios"
import { MAIN_API } from "../MAIN_API"
import { changeStateValue } from "../redux/MainReducer";


export const getContent = (lang_id = "1") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/content`, {
        params: { lang_id: lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'contentData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}



export const getBranch = (lang_id = "1") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/branch`, {
        params: { lang_id: lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'branchData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}

export const getSingleProducts = (id, lang_id) => async dispatch => {
    // console.log(id, lang_id)
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/product/${id}`, {
        params: { lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'productSingleData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}

export const getSingleDetails = (id, lang_id) => async dispatch => {
    // console.log(id, lang_id)
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/product/${id}`, {
        params: { lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'detailSingleData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}



export const getSingleProductType = (lang_id) => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/product-type`, {
        params: { lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'productType',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}


export const getProductCategories = (lang_id = "1") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/product-category`, {
        params: { lang_id: lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'productListData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}

export const getDetailsCategories = (lang_id = "1") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/detail-category`, {
        params: { lang_id: lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'detailsListData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}


export const getCooperationCompanies = (lang_id = "1") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/cooperation-companies`, {
        params: { lang_id: lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'cooperationCompaniesData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}


export const getourwork = (lang_id) => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/our-work`, {
        params: { lang_id: lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'ourWorkData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}


export const getProducts = (lang_id, page, category_id = "", sub_category_id = "", filter_type) => async dispatch => {
    // console.log(filter_type)
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/products`, {
        params: { lang_id: lang_id, page, category_id, sub_category_id, filter_type }

    }).then(resp => {
        dispatch(changeStateValue({
            name: 'productsData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}


export const getMostSaleProducts = (lang_id, page, filter_type) => async dispatch => {
    // console.log(filter_type)
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/products`, {
        params: { lang_id: lang_id, page, filter_type }

    }).then(resp => {
        dispatch(changeStateValue({
            name: 'mostSaleProductData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}

export const getsingleProductsdata = (lang_id, page, id) => async dispatch => {
    // console.log(filter_type)
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/products`, {
        params: { lang_id: lang_id, page, id }

    }).then(resp => {
        dispatch(changeStateValue({
            name: 'productsData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}



export const getOfferStatusProducts = (lang_id, page, filter_type) => async dispatch => {
    // console.log(filter_type)
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/products`, {
        params: { lang_id: lang_id, page, filter_type }

    }).then(resp => {
        dispatch(changeStateValue({
            name: 'offerStatusProductData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}

export const getDetails = (lang_id, page, category_id = "", sub_category_id = "") => async dispatch => {
    // console.log(category_id, sub_category_id)
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/details`, {
        params: { lang_id: lang_id, page, category_id, sub_category_id }

    }).then(resp => {
        dispatch(changeStateValue({
            name: 'detailsData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}


export const getAboutUs = (lang_id) => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/about`, {
        params: { lang_id: lang_id }

    }).then(resp => {
        dispatch(changeStateValue({
            name: 'aboutData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}


export const postEmail = (data) => async dispatch => {
    const { email } = data;

    const postData = {
        email
    };

    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.post(`${MAIN_API}/mail-send`, postData)
        .then(resp => {
            dispatch(changeStateValue({ name: 'loader', value: false }))
            return "success";
        }).catch(err => {
            console.log(err.response)
            dispatch(changeStateValue({ name: 'loader', value: false }))
            return "error"
        })
}


export const getSingleProduct = (id) => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/products/${id}`, {
        // params:{type:2}
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'productsData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}






export const searchInput = (data) => async (dispatch) => {
    const { search } = data;

    dispatch(changeStateValue({ name: 'loader', value: true }));

    try {
        const response = await axios.get(`${MAIN_API}/main-search`, {
            params: { search, lang_id: 1 },
        });

        // console.log(response.data)

        dispatch(changeStateValue({ name: 'searchResults', value: response.data }));

        dispatch(changeStateValue({ name: 'loader', value: false }));

        return response.data;

    } catch (error) {
        console.error('API Hatası:', error.response || error.message);

        dispatch(changeStateValue({ name: 'loader', value: false }));

        return { status: 'error', message: error.response?.data || 'Bir hata oluştu' };
    }
};