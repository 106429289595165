import React, { Component } from 'react';
import logo from '../../images/logo.png';
import SearchSvg from '../svg/SearchSvg';
import { Link, NavLink } from 'react-router-dom';
import HamburgerSvg from '../svg/HamburgerSvg';
import { connect } from 'react-redux';
import SearchInput from './SearchInput';

export class Nav extends Component {
    state = {
        inpRef: React.createRef()
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("click", this.handleClickOutside);
    }

    handleScroll = () => {
        const nav = document.querySelector('.navbar');
        if (window.scrollY >= 50) {
            nav.style.position = 'fixed';
            nav.style.top = '0px';
        } else {
            nav.style.position = 'inherit';
        }
    };

    handleClickOutside = (event) => {
        const { inpRef } = this.state;
        if (
            inpRef.current &&
            !inpRef.current.contains(event.target) &&
            !document.querySelector('.lbl-resp').contains(event.target)
        ) {
            this.closeNav(inpRef);
        }
    };

    closeNav = (inpRef) => {
        if (inpRef?.current) {
            inpRef.current.checked = false;
        }
    };

    render() {
        const { contentData } = this.props;
        const { inpRef } = this.state;

        return (
            <nav className="w-full p-[25px_0] bg-[#f7f8fa] z-[990] navbar max-[1024px]:border-b-[2px] max-[1024px]:p-[10px_0px]   max-[1024px]:mb-[30px]">
                <div className="flex container items-center gap-[32px] max-[1024px]:justify-between w-full max-[1024px]:w-full">
                    <div>
                        <Link to="/">
                            <img src={logo} className="w-[110px] h-[40px]" alt="Logo" />
                        </Link>

                    </div>


                    <SearchInput hidden='hidden  max-[1024px]:block  max-[400px]:hidden' />



                    <div className="menu ">


                        <input ref={inpRef} type="checkbox" id="onclick" className="inp_check" />

                        <label htmlFor="onclick" className="lbl-resp">
                            <HamburgerSvg fill="#f05127" />
                        </label>

                        <ul className="flex navlist items-center w-full gap-[50px] max-[1110px]:gap-[26px]">
                            <li>
                                <NavLink
                                    to="/"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px]  text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.nav_home}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/products"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.nav_product}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/details"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.nav_detail}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/contact"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.sh_branch}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/about-us"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.nav_about_us}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/project"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.nav_project}
                                </NavLink>
                            </li>

                            <li >
                                <SearchInput hidden='max-[1024px]:hidden' />
                            </li>


                        </ul>
                    </div>



                </div>

                <div className='max-[400px]:block hidden w-full max-[400px]:flex justify-center max-[400px]:mt-[12px]'>
                    <SearchInput />
                </div>
            </nav >
        );
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData,
    lang_id: state.Data.lang_id
});

export default connect(mapStateToProps)(Nav);