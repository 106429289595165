import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";


export const MainSlice = createSlice({
    name: "MAIN_SLICE",
    initialState: initialState,
    reducers: {
        changeStateValue: (state, action) => {
            const incld = action.payload.name.includes('.');
            state.loader = false
            if (!incld) {
                state[action.payload.name] = action.payload.value
            } else {
                const groups = action.payload.name.split('.');

                if (groups.length === 2) {
                    state[groups[0]][groups[1]] = action.payload.value
                } else if (groups.length === 3) {
                    state[groups[0]][groups[1]][groups[2]] = action.payload.value
                } else if (groups.length === 4) {
                    state[groups[0]][groups[1]][groups[2]][groups[3]] = action.payload.value
                } else if (groups.length === 5) {
                    state[groups[0]][groups[1]][groups[2]][groups[3]][groups[4]] = action.payload.value
                } else if (groups.length === 6) {
                    state[groups[0]][groups[1]][groups[2]][groups[3]][groups[4]][groups[5]] = action.payload.value;
                } else if (groups.length === 7) {
                    state[groups[0]][groups[1]][groups[2]][groups[3]][groups[4]][groups[5]][groups[6]] = action.payload.value;
                }


            }
            // 
        },
        resetStateValue: (state, action) => {

            const incld = action.payload.name.includes('.');

            if (!incld) {
                state[action.payload.name] = initialState[action.payload.name]
            } else {
                const groups = action.payload.name.split('.');
                if (groups.length === 2) {
                    state[groups[0]][groups[1]] = initialState[groups[0]][groups[1]]
                } else if (groups.length === 3) {
                    state[groups[0]][groups[1]][groups[2]] = initialState[groups[0]][groups[1]][groups[2]]
                } else if (groups.length === 4) {
                    state[groups[0]][groups[1]][groups[2]][groups[3]] = initialState[groups[0]][groups[1]][groups[2]][groups[3]]
                } else if (groups.length === 5) {
                    state[groups[0]][groups[1]][groups[2]][groups[3]][groups[4]] = initialState[groups[0]][groups[1]][groups[2]][groups[3]][groups[4]]
                }

            }
        },
        setLoader: (state, action) => {
            state.loader = true
        },
        hideLoader: (state, action) => {
            state.loader = false
        },
        setLongLoader: (state, action) => {
            state.loaderLong = true
        },
        hideLongLoader: (state, action) => {
            state.loaderLong = false
        }


    }
});

export const Data = MainSlice.reducer;
export const { changeStateValue, resetStateValue, addValue, setLoader, hideLoader, setLongLoader, hideLongLoader } = MainSlice.actions;
