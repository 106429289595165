import React, { Component } from 'react'

export class SearchSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    
  >
    <path
      fill="#1B1B29"
      fillRule="evenodd"
      stroke="#1B1B29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M8.415 1.809c0-.323.262-.585.585-.585 4.42 0 8 3.579 8 8 0 4.42-3.58 8-8 8s-8-3.58-8-8A7.99 7.99 0 0 1 5.379 2.09a.585.585 0 0 1 .53 1.044 6.819 6.819 0 0 0-3.738 6.089A6.826 6.826 0 0 0 9 16.053a6.826 6.826 0 0 0 6.83-6.83A6.826 6.826 0 0 0 9 2.395a.585.585 0 0 1-.585-.585ZM15.251 16.028a.857.857 0 0 1 1.212 0l2.286 2.285a.857.857 0 1 1-1.212 1.213L15.25 17.24a.857.857 0 0 1 0-1.212Z"
      clipRule="evenodd"
    />
  </svg>
    )
  }
}

export default SearchSvg