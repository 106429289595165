import React, { Component } from 'react'
import Card7 from '../components/cards/Card7'
import ProductListSection from '../components/ProductListSection'
import SectionHeader from '../components/SectionHeader'
import { getProducts } from '../actions/MainAction'
import { connect } from 'react-redux'
import CatalogSvg from '../components/svg/CatalogSvg'
import LoadMoreBtn from '../components/LoadMoreBtn'
import { Helmet } from 'react-helmet'

export class Products extends Component {
  state = {
    menuVisible: false,
    screenWidth: window.innerWidth,
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuVisible: !prevState.menuVisible,
    }));
  };

  componentDidMount() {
    const { getProducts } = this.props;
    getProducts(this.props.lang_id, 1);

    window.addEventListener('resize', this.updateScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  }

  updateScreenWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  loadMore = (page) => {
    const { lang_id } = this.props;
    this.props.getProducts(lang_id, page);
  }

  render() {
    const { menuVisible, screenWidth } = this.state;
    const { productsData, contentData } = this.props;

    // console.log(productsData)

    return (
      <main>
        <Helmet>
          <title>{contentData?.helmet_title_products}</title>

          <meta name="description" content="AİK şirkətinin aik.az saytında ən geniş çeşidli soyuducu məhsulları. Keyfiyyətli və sərfəli qiymətlərlə soyuducular." />

          <meta name="keywords" content="soyuducu məhsulları, soyuducu modelləri, keyfiyyətli soyuducular, soyuducu qiymətləri, soyuducu seçimi, vitrin soyuducular, su soyuducuları, dolab tipli soyuducular, südlük dolablar, dərin dondurucular, hovuz dərin dondurucular, soyuq otaq kameraları" />

          <meta property="og:title" content="Soyuducu Məhsulları | AİK - aik.az" />
          <meta property="og:description" content="AİK şirkətinin aik.az saytında ən geniş çeşidli soyuducu məhsulları. Keyfiyyətli və sərfəli qiymətlərlə soyuducular." />
          <meta property="og:image" content="https://aik.az/images/avadanliq.JPG" />
          <meta property="og:url" content="https://www.aik.az/products" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Soyuducu Məhsulları | AİK - aik.az" />
          <meta name="twitter:description" content="AİK şirkətinin aik.az saytında ən geniş çeşidli soyuducu məhsulları. Keyfiyyətli və sərfəli qiymətlərlə soyuducular." />
          <meta name="twitter:image" content="https://aik.az/images/avadanliq.JPG" />

        </Helmet>

        <section className='container flex w-full'>
          <div className='rounded-[12px] hidden max-[1024px]:inline-block'>
            <label
              id='kataloq'
              className='flex items-center gap-[6px]'
              onClick={this.toggleMenu}
            >
              <CatalogSvg />
              <span className='font-[500] text-[18px] text-[#00]'>{contentData?.catalog}</span>
            </label>
          </div>
        </section>

        <section className='flex items-start h-full gap-[32px] container pt-[32px]'>
          <div className='h-[full]'>
            {/* Show ProductListSection only when screen is larger than 1024px or when menuVisible is true */}
            {menuVisible && screenWidth <= 1024 && (
              <div className={`product-list-wrapper listclass ${menuVisible ? 'visible' : ''} ${menuVisible && screenWidth <= 1024 ? 'full-screen' : ''}`}>
                <ProductListSection closeMenu={this.toggleMenu} />
              </div>
            )}

            {screenWidth > 1024 && (
              <div className='product-list-wrapper listclass '>
                <ProductListSection closeMenu={this.toggleMenu} />
              </div>
            )}

          </div>

          <div className='flex flex-col w-full'>

            <div className='mt-[-10px] mb-[20px]'>
              <div className='flex justify-start w-full gap-[8px]'>
                <h2 className='font-[500] text-[40px] text-center text-[#030303] max-[550px]:text-[30px] max-[400px]:text-[26px]'>
                  {contentData?.nav_product}
                </h2>
              </div>
            </div>

            <div className='grid grid-cols-3 gap-[32px] max-[1024px]:gap-[20px] max-[1024px]:grid-cols-4 max-[768px]:grid-cols-3 max-[580px]:grid-cols-2 max-[370px]:grid-cols-1'>
              {productsData?.map((data, i) => (
                <Card7
                  key={i}
                  image_full_url={data?.images[0]?.image_full_url}
                  description={data?.translate?.description}
                  title={data?.translate?.title}
                  id={data?.id}
                  price={data?.price}
                />
              ))}
            </div>

            {productsData?.length >= 20 ? (
              <LoadMoreBtn onLoadData={this.loadMore} defCount={20} count={productsData?.length} />
            ) : null}
          </div>
        </section>
      </main>
    );
  }
}

const mapStateProps = (state) => ({
  lang_id: state.Data.lang_id,
  productsData: state.Data.productsData,
  contentData: state.Data.contentData,
});

const mapDispatchToProps = { getProducts };
export default connect(mapStateProps, mapDispatchToProps)(Products);