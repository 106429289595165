import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchSvg from '../svg/SearchSvg';
import { searchInput } from '../../actions/MainAction';
import Card13 from '../cards/Card13';

export class SearchInput extends Component {
    state = {
        search: '',
    };

    handleChange = (e) => {
        const value = e.target.value;
        this.setState({ search: value });
    };

    handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            const { search } = this.state;

            if (search.trim() === '') {
                return;
            }

            const response = await this.props.searchInput({ search });
            if (response.status !== 'error') {
                this.setState({ results: response.data || [] });

            } else {
                console.error("Sonuç alınamadı.");
            }
        }
    };

    render() {
        const { search, results } = this.state;

        const { searchResults } = this.props;

        // console.log(searchResults);

        return (
            <div className={`flex flex-col items-start  w-[250px] ${this.props.hidden} `}>
                <div className="flex items-center w-full border-[1px] border-[#363743]  p-[10px_18px] rounded-[50px]">
                    <input
                        className="bg-[#f7f8fa] w-full"
                        type="text"
                        placeholder="axtar..."
                        value={search}
                        onChange={this.handleChange} // Her değişiklikte tetiklenir
                        onKeyDown={this.handleKeyDown} // Enter tuşu kontrolü
                    />
                    <SearchSvg />
                </div>

                {searchResults && searchResults.length > 0 && (
                    <div className="w-full fixed z-[800] mt-[55px] ml-[15px] max-[1024px]:mt-[5px] max-[400px]:mt-[50px] ">
                        <div className="max-h-[200px] w-[220px] overflow-y-auto ">
                            {searchResults.map((data, index) => (
                                <Card13
                                    key={index}
                                    title={data?.translate?.title}
                                    type={data?.type}
                                    id={data?.id}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    searchResults: state.Data.searchResults,
});

const mapDispatchToProps = { searchInput };

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
